import React, { Component } from 'react';
import { connect } from 'react-redux';

import { includes } from 'lodash';

import 'styles/layouts/Hero.less';
import UserStatusNotification from 'components/UserStatusNotification';
import SMSOptInAlert from '../components/SMSOptInAlert';

class Hero extends Component {
    constructor(props) {
        super(props);

        this.state = {
            image: props.images[3],
        };
    }

    componentDidMount() {
        this.getImage();
        window.addEventListener('resize', this.getImage);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getImage);
    }

    getImage = () => {
        if (typeof window === 'undefined') {
            this.setState({ image: this.props.images[0] }); // xxs
            return this.props.images[0];
        }

        switch (true) {
            case (window.innerWidth <= 320):
                this.setState({ image: this.props.images[0] }); // xxs
                return this.props.images[0];
            case (window.innerWidth <= 480):
                this.setState({ image: this.props.images[1] }); // xs
                return this.props.images[1];
            case (window.innerWidth <= 768):
                this.setState({ image: this.props.images[2] }); // sm
                return this.props.images[2];
            case (window.innerWidth <= 992):
                this.setState({ image: this.props.images[3] }); // md
                return this.props.images[3];
            default: // 1200 and higher
                this.setState({ image: this.props.images[4] }); // lg
                return this.props.images[4];
        }
    };

    render() {
        const {
            hero_title = '',
            hero_description = '',
        } = this.props;
        if (!includes(this.props.styles, 'home-hero')) {
            return (
                <div className={this.props.styles} style={{ background: this.props.background }}>
                    <SMSOptInAlert />
                    <UserStatusNotification with_container={false} />
                    <div className="sub-container">
                        <h1 className="hero-title">{hero_title}</h1>
                        <img src={this.state.image} alt={hero_description} className="hero-image" />
                    </div>
                </div>
            );
        }
            return (
                <div className={this.props.styles} style={{ background: this.props.background }}>
                    <SMSOptInAlert />
                    <UserStatusNotification with_container={false} />
                    <div className="sub-container">
                        <h1 className="hero-title">{hero_title}</h1>
                        <div className="hero-description">{hero_description}</div>
                        <div className="hero-child">{this.props.children}</div>
                        <img src={this.state.image} alt={hero_description} className="hero-image" />
                    </div>
                </div>
            );
    }
}


const mapStateToProps = ({}) => ({});

export default connect(mapStateToProps, {})(Hero);
