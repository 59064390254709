import React, { Component } from 'react';
import 'styles/components/Blurb.less';

class Blurb extends Component {
    state = {
        class: '',
        classPush: '',
        classPull: '',
    };

    componentWillMount() {
        this.getSize();
    }

    componentDidMount() {
        typeof window !== 'undefined' && window.addEventListener('resize', this.getSize);
    }

    componentWillUnmount() {
        typeof window !== 'undefined' && window.removeEventListener('resize', this.getSize);
    }


    getSize = () => {
        const innerWidth = typeof window !== 'undefined' && window.innerWidth;

        switch (true) {
            case (innerWidth < 900):
                this.setState({
                    class: 'col-xxs-12 col-md-6',
                    classBlurb: 'blurbContent col-xxs-12 col-md-6',
                    classPush: 'col-xxs-12 col-md-6 col-md-push-6',
                    classPull: 'blurbContent col-xxs-12 col-md-6 col-md-pull-6',
                });
                break;
            case (innerWidth >= 900):
                this.setState({
                    class: 'col-xxs-12 col-sm-6',
                    classBlurb: 'blurbContent col-xxs-12 col-sm-6',
                    classPush: 'col-xxs-12 col-sm-6 col-sm-push-6',
                    classPull: 'blurbContent col-xxs-12 col-sm-6 col-sm-pull-6',
                });
                break;
            default:
                this.setState({
                    class: 'col-xxs-12 col-md-6',
                    classBlurb: 'blurbContent col-xxs-12 col-md-6',
                    classPush: 'col-xxs-12 col-md-6 col-md-push-6',
                    classPull: 'blurbContent col-xxs-12 col-md-6 col-md-pull-6',
                });
        }

        return this.state.image;
    };

    render() {
        return (
          <div className="blurb">
            <div className="row">
              <div className={this.state.class}>
                  <img src={this.props.blurbs[0].image} alt={this.props.blurbs[0].title} className="blurbImg" />
              </div>
              <div className={this.state.classBlurb}>
                <span className="blurbHeader">{this.props.blurbs[0].title}</span>
                <div className="blurbBody">{this.props.blurbs[0].content}</div>
              </div>
            </div>
            <div className="row">
              <div className={this.state.classPush}>
                  <img src={this.props.blurbs[1].image} alt={this.props.blurbs[1].title} className="blurbImg" />
              </div>
              <div className={this.state.classPull}>
                <span className="blurbHeader">{this.props.blurbs[1].title}</span>
                <div className="blurbBody">{this.props.blurbs[1].content}</div>
              </div>
            </div>
            <div className="row">
              <div className={this.state.class}>
                  <img src={this.props.blurbs[2].image} alt={this.props.blurbs[2].title} className="blurbImg" />
              </div>
              <div className={this.state.classBlurb}>
                <span className="blurbHeader">{this.props.blurbs[2].title}</span>
                <div className="blurbBody">{this.props.blurbs[2].content}</div>
              </div>
            </div>
          </div>
        );
    }
}


export default Blurb;
